import { Close, Menu } from "@mui/icons-material"
import { AppBar, Button, Dialog, Hidden, IconButton, Slide, Toolbar, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { Box } from "@mui/system"
import React, { useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { SectionIdEnum } from "types"


export type NavigationProps = {
    isMedium: boolean;
}

const navigationItems = [
    {
        text: "Intro",
        to: SectionIdEnum.newintro
    },
    {
        text: "About",
        to: SectionIdEnum.newabout
    },
    {
        text: "Skills",
        to: SectionIdEnum.skills
    },
    {
        text: "Projects",
        to: SectionIdEnum.project
    },
    {
        text: "Contact",
        to: SectionIdEnum.contact
    },
]

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) => {
    return <Slide direction="left" ref={ref} {...props} />;
})



export const Navigation: React.FC<NavigationProps> = ({ isMedium }) => {
    const [open, setOpen] = useState(false);


    const onOpenHandler = () => setOpen(true);
    const onCloseHandler = () => setOpen(false);


    const mappedItems = (
        navigationItems.map(({ to, text }) => {
            return (
                <AnchorLink className="nav-items" key={to} href={`#${to}`} offset={isMedium ? '56px' : '64px'} onClick={onCloseHandler}>
                    <Button color="inherit" size="large" fullWidth={isMedium}>{text}</Button>
                </AnchorLink >
            )
        })
    )

    return (
        <>
            <Hidden mdDown>
                <Box display="flex" gap={2}>
                    {mappedItems}
                </Box>
            </Hidden>
            <Hidden mdUp>
                <IconButton color="inherit" onClick={onOpenHandler}>
                    <Menu />
                </IconButton>
            </Hidden>
            <Dialog
                open={open}
                fullScreen
                fullWidth
                TransitionComponent={Transition}
                hidden={!isMedium}
                hideBackdrop
                PaperProps={{
                    sx: {
                        boxShadow: 'none'
                    }
                }}
            >
                <AppBar position="static" sx={{ background: 'white', color: 'text.primary' }}>
                    <Toolbar>
                        <Typography variant="h5" sx={{ flexGrow: 1 }}>
                            Menu
                        </Typography>
                        <IconButton color="inherit" onClick={onCloseHandler}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box display="flex" flexDirection="column" py={3} width="100%">
                    {mappedItems}
                </Box>
            </Dialog>
        </>
    )

}