// SVG Hover Circle
import React, { CSSProperties, useEffect, useState } from 'react';
import "./timeline.circle.scss";
import ExpandingCircle from './timeline.expandcircle';

interface SVGCircleProps {
    cx: number;
    cy: number;
    r: number;
    currentNode: boolean;
    onHover?: (hovering: boolean) => void;
}

export const SVGCircle: React.FC<SVGCircleProps> = ({ cx, cy, r, currentNode, onHover }) => {
    const [isHovered, setHovered] = useState(false);
    const [expandCircleStyle, setExpandCircleStyle] = useState<CSSProperties>({});

    useEffect(()=> {
        const style = document.createElement('style');
        document.head.appendChild(style);
        const styleSheet = style.sheet as CSSStyleSheet;

        const keyframes = `@keyframes expandCircle {
            from {
            r: ${r+4};
            }
            to {
            r: ${r+14};
            }
        }
        `
        styleSheet.insertRule(keyframes, 0);

        setExpandCircleStyle({
            strokeWidth: "3",
            animation: `expandCircle 0.2s forwards`
          });
        
        return () => {
            document.head.removeChild(style);
        };
    }, [])

    return (
        <>
            { currentNode && 
                <circle
                    className={ `${isHovered || currentNode ? 'hidden-circle' : ''}`}
                    style={expandCircleStyle}
                    cx={cx}
                    cy={cy}
                />
               /*isHovered &&
               <ExpandingCircle
                    cx={cx}
                    cy={cy}
                    initialRadius={0}
                    finalRadius={r+10}
                    color="red"
                />*/
            }
            <circle
                cx={cx}
                cy={cy}
                r={(isHovered || currentNode) ?  r+4 : r }
                className={ `${isHovered || currentNode ? 'hovered' : ''} timeline-circle`}
                onMouseEnter={() => {
                    setHovered(true);
                    onHover && onHover(true);
                }}
                onMouseLeave={() => {
                    setHovered(false);
                    onHover && onHover(false);
                }}
            />
        </>

    );
}


