import { Grid } from "@mui/material"
import { awsImg, golangImg, javaImg, nodejsImg, pythonImg, reactjsImg, sparkImg, typescriptImg } from "assets"
import { useAppSelector } from "redux/hooks/redux-hooks"
import { SkillsCard } from "./skills.card"

const skillsImage: Record<string, any> = {
    spark: sparkImg,
    nodejs: nodejsImg,
    reactjs: reactjsImg,
    typescript: typescriptImg,
    golang: golangImg,
    python: pythonImg,
    aws: awsImg,
    java: javaImg,
}

export type SkillsDetailProps = {
    isSmall: boolean,
}

export const SkillsDetail: React.FC<SkillsDetailProps> = ({ isSmall }) => {

    const skillsState = useAppSelector(state => state.basicReducer.skills);

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
        >
            {
                skillsState.map(({ rowkey, image, description }) => {
                    return (
                        <Grid item px={2} py={2} key={rowkey}>
                            <SkillsCard
                                isSmall={isSmall}
                                title={rowkey}
                                image={skillsImage[image]}
                                description={description}
                            />
                        </Grid>
                    )
                })
            }
        </Grid >

    )
}