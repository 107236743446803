import { 
    AboutModel, BasicInfoModel, ContactModel,
    IntroModel, ProjectModel, SkillModel, ResumeImgModel
} from "../model/basic-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialBasicInfoState: BasicInfoModel = {
    intro : null,
    about : [],
    skills : [],
    projects: [],
    contact: null,
    image: {},
}

const basicSlice = createSlice({
    name: 'basic',
    initialState: initialBasicInfoState,
    reducers: {
        setIntroInfo(state, action:PayloadAction<IntroModel>) {
            state.intro = action.payload;
        },
        setAboutInfo(state, action:PayloadAction<AboutModel[]>) {
            state.about = action.payload;
        },
        setSkillsInfo(state, action:PayloadAction<SkillModel[]>) {
            state.skills = action.payload;
        },
        setProjectsInfo(state, action:PayloadAction<ProjectModel[]>) {
            state.projects = action.payload;
        },
        setContactInfo(state, action:PayloadAction<ContactModel>) {
            state.contact = action.payload;
        },
        setResumeImage(state, action:PayloadAction<ResumeImgModel>) {
            state.image = action.payload;
        }
    }
})

export default basicSlice;