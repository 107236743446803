import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { NewAboutTimeline } from "./timeline/newabout.timeline";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { AboutTimeline } from "sections/about/about.timeline";

export const NewAboutSection: React.FC = () => {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const aboutState = useAppSelector(state => state.basicReducer.about);

    return (
        <Box
            display="flex"
            minHeight="100vh"
            justifyContent='center'
            flexDirection="column"
            textAlign="center"
            py={5}
        >

            <Typography variant={isSmall ? "h5" : "h3"} marginBottom={5}>About Me</Typography>

            <Box
                px={5}
                py={5}
                textAlign={'center'}
            >
                {
                    isMedium ? (
                        <AboutTimeline isMedium={isMedium} />
                    ) : (
                        <NewAboutTimeline events={aboutState} isLarge={isLarge}/>
                    )
                }
            </Box>
        </Box >
    );
}