import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from 'redux/hooks/redux-hooks';
import './newintro.scss';
import ImageLoader from "utils/ImageLoader";

export const NewIntroSection: React.FC = () => {

    const introState = useAppSelector(state => state.basicReducer.intro);
    const ImgState = useAppSelector(state => state.basicReducer.image);
    const [rotation, setRotation] = useState(0);
    const [visibleTextCount, setVisibleTextCount] = useState(0);

    const bgimg = ImgState["newintro-bgimg"];
    const gearimg = ImgState["newintro-gear"];

     // todo isSmall don't implement, kyle chiang 2023/10/11
    const theme = useTheme();
    const isMed = useMediaQuery(theme.breakpoints.down('md'));

    const handleScroll = () => {
        const section = document.getElementById('newintro-section');
        if(!section) return;

        // set gear rotation
        const rect = section.getBoundingClientRect();
        const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
        const percentageVisible = visibleHeight / section.offsetHeight;

        setRotation(360 * percentageVisible);

        // set text visible
        const texts = Array.from(section.querySelectorAll('.newintro__text'));
        if (rect.top < window.innerHeight && rect.bottom > 0) {
            const percentageScrolled = (window.innerHeight - rect.top) / window.innerHeight;
            const itemsToShow = Math.ceil(texts.length * percentageScrolled);
            setVisibleTextCount(itemsToShow);
        } else {
            setVisibleTextCount(0);
        }
    }

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <Box
            id="newintro-section"
            className="newintro-section"
            display="flex"
            minHeight="100vh"
            justifyContent='center'
            flexDirection="column"
            px={isMed ? 5 : 20}
            py={2}
            sx={{
                backgroundSize: "cover",
                backgroundImage: `url(${bgimg})`
            }}
        >
            {
                isMed ? (
                    <>
                        <Typography
                            variant="h4"
                            className={`newintro__text ${visibleTextCount > 0 ? 'visible' : ''}`}
                            data-order="1"
                            style={{ fontFamily: 'Arial, sans-serif' }}
                        >
                            {introState?.["intro-title"]}
                        </Typography>

                        <Typography
                            variant="h5"
                            className={`newintro__text ${visibleTextCount > 1 ? 'visible' : ''}`}
                            data-order="2"
                            style={{ fontFamily: 'Arial, sans-serif' }}
                        >
                            {introState?.["intro-subtitle"]}
                        </Typography>

                        <Typography
                            py={3}
                            variant="h6"
                            className={`newintro__text ${visibleTextCount > 2 ? 'visible' : ''}`}
                            data-order="3"
                            style={{ fontFamily: 'Arial, sans-serif' }}
                        >
                            {introState?.["intro-description"]}
                        </Typography>
                    </>        
                ) : (
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid 
                            item xs={3}
                            textAlign="center"
                        >
                        <Box px={2}>
                            <ImageLoader 
                                src={`${gearimg}`}
                                placeholder={`${gearimg}`}
                                width="100%"
                                styles={{
                                    transform: `rotate(${rotation}deg)`
                                }}
                            />
                        </Box>
        
                        </Grid>
                        <Grid
                            item 
                            xs={9}
                        >
                            <Box px={10}>
                                <Typography
                                    variant="h3"
                                    className={`newintro__text ${visibleTextCount > 0 ? 'visible' : ''}`}
                                    data-order="1"
                                    style={{ fontFamily: 'Arial, sans-serif' }}
                                >
                                    {introState?.["intro-title"]}
                                </Typography>
        
                                <Typography
                                    variant="h4"
                                    className={`newintro__text ${visibleTextCount > 1 ? 'visible' : ''}`}
                                    data-order="2"
                                >
                                    {introState?.["intro-subtitle"]}
                                </Typography>
        
                                <Typography
                                    py={3}
                                    variant="h6"
                                    width="100%"
                                    className={`newintro__text ${visibleTextCount > 2 ? 'visible' : ''}`}
                                    data-order="3"
                                >
                                    {introState?.["intro-description"]}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    )
}