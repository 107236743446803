import logo from './kyle-logo.png';
import introback from './intro-background.png';
import aboutback from './about-background.png';
import skillsback from './skills-background.png';
import contactback from './contact-background.png';

import spark from './skills/Spark.png';
import java from './skills/Java.png';
import nodejs from './skills/Nodejs.png';
import reactjs from './skills/ReactJS.png';
import typescript from './skills/Typescript.png';
import golang from './skills/Golang.png';
import python from './skills/Python.png';
import aws from './skills/AWS.png';

import flume from './projects/Flume.png';
import nginx from './projects/Nginx.png';
import iot from './projects/IOT.png';

export const logoImg = logo;
export const introbackImage = introback;
export const aboutbackImage = aboutback;
export const skillsbackImage = skillsback;
export const contactbackImage = contactback;

// Skills images
export const sparkImg = spark;
export const javaImg = java;
export const nodejsImg = nodejs;
export const reactjsImg = reactjs;
export const typescriptImg = typescript;
export const golangImg = golang;
export const pythonImg = python;
export const awsImg = aws;

// projects images
export const flumeImg = flume;
export const nginxImg = nginx;
export const iotImg = iot;