import React, { useState } from 'react';
import '../sections/newIntro/newintro.scss';
interface StyleProps {
    color?: string;
    fontSize?: string;
    backgroundColor?: string;
    transform?: string;
  }

interface ImageLoaderProps {
  classname?: string;
  src: string;
  alt?: string;
  placeholder?: string; // URL to a placeholder image or a spinner
  errorImage?: string;  // URL to an image that shows when the main image fails to load
  width?: string;
  styles?: StyleProps;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ classname, width, src, alt, placeholder, errorImage, styles }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = (e: any) => {
    //console.log(e.target.src);
    setLoading(false);
    setError(true);
  };

  return (
    <>
        {
            //console.log(src)
        }
        {
            //console.log("error : " + error + " loading : " + loading)
        }
        {!error && <img
            alt='image'
            className={classname} width={width} src={src}
            onLoad={onLoad} onError={onError}
            style={{ display: loading ? 'none' : 'block', ...styles} }
        />}
    </>
  );
};

export default ImageLoader;