import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { CloudQueue, Refresh, Start, Tv } from '@mui/icons-material';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks/redux-hooks';

let IconLists: Record<string, JSX.Element> = { // also can use the { [key: string]: JSX.Element}
    start: <Start />,
    tv: <Tv />,
    refresh: <Refresh />,
    cloudqueue: <CloudQueue />
}

export type AboutTimelineProps = {
    isMedium: Boolean,
}

export const AboutTimeline: React.FC<AboutTimelineProps> = ({ isMedium }) => {

    const [currentTime, setCurrentTime] = React.useState(0);

    const ClickDot = (index: any) => setCurrentTime(index)

    const aboutState = useAppSelector(state => state.basicReducer.about);

    const timelineSVG = (
        aboutState.map(({ company, date, subdescription, icon }, index) => {
            return (
                <TimelineItem key={index}>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        {date}
                    </TimelineOppositeContent>

                    {
                        isMedium ? (
                            <>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot>
                                        {icon !== "" ? IconLists[icon] : <Start />}
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        {company}
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{subdescription}</Typography>
                                </TimelineContent>
                            </>
                        ) : (
                            <>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot sx={{ cursor: 'pointer' }} onClick={() => ClickDot(index)}>
                                        {icon !== "" ? IconLists[icon] : <Start />}
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        {company}
                                    </Typography>
                                    <Typography sx={{ fontSize: '15px' }}>{subdescription}</Typography>
                                </TimelineContent>
                            </>
                        )
                    }

                </TimelineItem>
            )
        })
    )

    return (
        <>
            <Grid
                container
                spacing={2}
            >
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                        }}>
                            <Timeline position="right">
                                {timelineSVG}
                            </Timeline>
                        </Box>
                    </Grid>
                </Hidden>

                <Hidden mdDown>
                    <Grid item xs={5}>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                        }}>
                            <Timeline position="right">
                                {timelineSVG}
                            </Timeline>
                        </Box>
                    </Grid>

                    <Grid item xs={7}>
                        <Box px={10} py={10}>
                            {
                                aboutState.length > 0 && <Typography variant='h5' textAlign="left">{aboutState[currentTime].fulldescription}</Typography>
                            }
                        </Box>
                    </Grid>
                </Hidden>

            </Grid>

        </>
    )
}