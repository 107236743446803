import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { skillsbackImage } from "assets"
import { SkillsDetail } from "./skills.details";


export const SkillsSection: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Box display="block"
            minHeight="100vh"
            sx={{
                backgroundSize: "cover",
                backgroundImage: `url(${skillsbackImage})`
            }}
        >
            {
                isSmall ? (
                    <Box width="100%" textAlign="center">
                        <Typography fontFamily="Arial" px={10} py={5} variant="h5">Main Focus Skills</Typography>
                    </Box>
                ) : (
                    <Box width="100%" textAlign="center">
                        <Typography fontFamily="Arial" px={10} py={5} variant="h3">Main Focus Skills</Typography>
                    </Box >
                )
            }
            <Box display="flex"
                minHeight="70vh"
                pb={10}
                justifyContent='center'
                flexDirection="column"
                textAlign="center">
                <SkillsDetail isSmall={isSmall} />
            </Box>
        </Box >
    );
}