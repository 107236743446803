import { AppBar, Box, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { logoImg } from "assets";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "types";
import { Navigation } from "./navigation/navigation.component";

export type MainLayoutProps = {
    children: React.ReactNode
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    return <Box height="100vh">
        <AppBar position="fixed" sx={{ background: 'black' }}>
            <Toolbar>
                <Box flexGrow={1}>
                    <AnchorLink className="nav-items" href={`#${SectionIdEnum.intro}`} offset={isMedium ? '56px' : '64px'}>
                        <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                            <img width="45px" height="45px" src={logoImg} alt="logo" />
                            <Typography variant="h5" sx={{ width: 'fit-content' }}>CodeHouse</Typography>
                        </Box>
                    </AnchorLink>
                </Box>
                <Navigation isMedium={isMedium} />
            </Toolbar>
        </AppBar>
        <Box>
            <Toolbar />
            {children}
        </Box>
    </Box>
}