import { Box, Card, CardContent, CardMedia, Hidden, Typography } from "@mui/material"


export type SkillsCardProps = {
    isSmall: boolean,
    title: string,
    image: string,
    description: string,
}

export const SkillsCard: React.FC<SkillsCardProps> = ({ isSmall, title, image, description }) => {
    return (
        <Card
            raised
            sx={{
                borderRadius: '30px',
                display: 'flex',
                maxWidth: 400,
                height: 300,
                margin: "0 auto",
                padding: "0.1em",
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" textAlign="left" color="text.secondary" component="div" px={2} marginTop={isSmall ? 5 : 2}>
                        {description}
                    </Typography>
                </CardContent>
            </Box>
            <Hidden smDown>
                <Box px={2} py={2}>
                    <CardMedia
                        sx={{
                            height: 'auto',
                            width: 'auto',
                            maxWidth: '150px',
                            maxHeight: '150px',
                            borderRadius: '20px'
                        }}
                        component="img"
                        image={image}
                        alt="skill images"
                    />
                </Box>
            </Hidden>
        </Card>

    )
}