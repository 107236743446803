import basicSlice from "./basic-slice";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import basicService from "../service/basicService";

export const basicActions = basicSlice.actions;

export const fetchBasicInfo = ():ThunkAction<void, RootState, unknown, AnyAction > => {
    return async(dispatch, getState) => {
        const response = await basicService.getBasicInfo();

        if(response.hasOwnProperty("body")) {
            if(response.body.hasOwnProperty("intro")) {
                dispatch(basicActions.setIntroInfo(response.body.intro));
            }

            if(response.body.hasOwnProperty("about")) {
                dispatch(basicActions.setAboutInfo(response.body.about));
            }

            if(response.body.hasOwnProperty("skills")) {
                dispatch(basicActions.setSkillsInfo(response.body.skills));
            }

            if(response.body.hasOwnProperty("projects")) {
                dispatch(basicActions.setProjectsInfo(response.body.projects));
            }

            if(response.body.hasOwnProperty("contact")) {
                dispatch(basicActions.setContactInfo(response.body.contact));
            }

            if(response.body.hasOwnProperty("images")) {
                dispatch(basicActions.setResumeImage(response.body.images));
            }
        }
    }
}