import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { contactbackImage } from "assets"
import { useAppSelector } from "redux/hooks/redux-hooks";

const contactMethodStringify = (str: string) => {
    const json: Record<string, string> = JSON.parse(str);
    let temp: Array<JSX.Element> | null = [];
    for (let key of Object.keys(json)) {
        if (json[key].includes("#")) {
            let strAry = json[key].split("#");
            temp = temp.concat(
                <Typography variant="h6" key={key}>
                    {`${key} :`} <a href={strAry[1]} target="_blank">{strAry[0]}</a>
                </Typography>
            )
        } else {
            temp = temp.concat(
                <Typography variant="h6" key={key}>
                    {`${key} : ${json[key]}`}
                </Typography>
            )
        }
    }
    return temp;
}


export const ContactSection: React.FC = () => {

    const contactState = useAppSelector(state => state.basicReducer.contact);

    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <Box display="flex"
            minHeight="100vh"
            sx={{
                backgroundSize: "cover",
                backgroundImage: `url(${contactbackImage})`,
                paddingBottom: "40px",
                paddingTop: "40px"
            }}
        >
            <Grid
                container
            >
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Box
                            py={2}
                            textAlign={'center'}
                        >
                            <Typography variant={isSmall ? "h5" : "h3"}>Contact</Typography>
                        </Box>
                        <Typography variant="h5">{contactState?.["contact-content"]}</Typography>
                        <Box
                            px={3}
                            py={2}
                            textAlign={'left'}
                        >
                        {
                            contactMethodStringify(contactState?.["contact-method"] === undefined ? "{}" : contactState["contact-method"])
                        }
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}