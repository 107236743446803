import { Box, CircularProgress } from "@mui/material";
import { MainLayout, SectionContainer } from "components"
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks/redux-hooks";
import { fetchBasicInfo } from "redux/store/basic-action";
import { 
    SkillsSection,ProjectSection, ContactSection, 
    NewIntroSection, NewAboutSection,
} from "sections";
import { SectionIdEnum } from 'types';

const sections = [
    {
        sectionId: SectionIdEnum.newintro,
        component: <NewIntroSection />,
    },
    {
        sectionId: SectionIdEnum.newabout,
        component: <NewAboutSection />,
    },
    
    {
        sectionId: SectionIdEnum.skills,
        component: <SkillsSection />,
    },
    {
        sectionId: SectionIdEnum.project,
        component: <ProjectSection />,
    },
    {
        sectionId: SectionIdEnum.contact,
        component: <ContactSection />,
    }
]

export const Main: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const loadData = async() => {
            try {
                await dispatch(fetchBasicInfo());
                setLoading(true);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        }
        loadData();
    });

    return (
        <>
            {
                !loading ?
                    <Box sx={{
                        display: 'flex', height: '100vh', alignItems: 'center',
                        justifyContent: 'center'
                    }
                    } >
                        <CircularProgress />
                    </Box >
                    :
                    <MainLayout>
                        {sections.map(
                            ({ component, sectionId }) => {
                                return <SectionContainer sectionId={sectionId} key={sectionId}>
                                    {component}
                                </SectionContainer>
                            }
                        )
                        }
                    </MainLayout>

            }
        </>)
};