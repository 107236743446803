import React, { useEffect, useRef, useState } from 'react';
import './newabout.timeline.scss';
import { SVGCircle } from './timeline.circle';
import { AboutModel } from 'redux/model/basic-models';
import { Box } from '@mui/material';
import { TimelineDes } from './timeline.description';

export type NewAboutTimelineProps = {
    isSmall: Boolean,

}

interface TimelineProps {
    events: AboutModel[],
    isLarge: Boolean,
}

export const NewAboutTimeline: React.FC<TimelineProps> = ({ events, isLarge }) => { 
  const totalEvents = events.length; 
  //const spacing = 165; // Distance between each event
  const [spacing, setSpacing] = useState(165);

  const [isHovered, setIsHovered] = useState(false);
  const [currentNode, setCurrentNode] = useState(1);

  const currentNodeRef = useRef(currentNode); // Initialize the ref with currentNode initial value
  const isHoveredRef = useRef(isHovered); // Initialize the ref with isHover initial value

  // control the timeline line's length
  // const lineRef = useRef<SVGLineElement>(null);

  useEffect(() => {
    const setLineLength = () => {
      const windowWidth = window.innerWidth;
      const lineLength = (windowWidth * 0.7 ) / events.length; 
      //lineRef.current.setAttribute('x2', String(lineLength));
      setSpacing(lineLength)
    }

    setLineLength();

    window.addEventListener('resize', setLineLength);

    return () => {
      window.removeEventListener('resize', setLineLength);
    }
  }, []);


  useEffect(() => {
    currentNodeRef.current = currentNode;
  }, [currentNode]);

  useEffect(() => {
    isHoveredRef.current = isHovered;
  }, [isHovered])

  useEffect(() => {
    const timer = setInterval(() => {
      if(!isHoveredRef.current) {
        setCurrentNode(prev => (prev % totalEvents) + 1 || 5);
      }
    }, 6000);
    return () => clearInterval(timer);
  }, [])


  return (
    <>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
      >
        <svg width={(totalEvents+1) * spacing} height="150" className="horizontal-timeline">
          {/*<line ref={lineRef} x1="0" y1="75" x2={(totalEvents+1) * spacing} y2="75" stroke="#3498db" strokeWidth="3" />*/}
          <line x1="0" y1="75" x2={spacing} y2="75" stroke="#3498db" strokeWidth="3" />
          {events.map((event, index) => (
            <g key={event.rowkey} transform={`translate(${(index+1) * spacing}, 0)`}>
              <line x1="0" y1="75" x2={spacing} y2="75" stroke="#3498db" strokeWidth="3" />
              <SVGCircle
                cx={0} cy={75} r={10} 
                currentNode={event.rowkey == currentNode ? true : false}
                onHover={(hovering) => {
                  if (hovering) {
                    setIsHovered(true)
                    setCurrentNode(event.rowkey);
                    // console.log('Circle is being hovered!');
                  } else {
                    setIsHovered(false)
                    // console.log('Hover ended.');
                  }
                }}
              />
              <text x="27" y="35" fontSize="14">{event.date}</text>
              <text x="27" y="60" fontSize="16" fontWeight="bold">{event.company}</text>
            </g>
          ))}
        </svg>
      </Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
      >
        <TimelineDes events={events} currentNode={currentNode} isLarge={isLarge} />
      </Box>
    </>
  );
}
