import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import React from "react"

export type ProjectListType = {
    project: string,
    image: any,
    description: string
}


export const ProjectList: React.FC<ProjectListType> = ({ project, image, description }) => {
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={image} />
                </ListItemAvatar>
                <ListItemText
                    primary={project}
                    secondary={
                        <React.Fragment>
                            {description}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}