import React from "react";
import './timeline.description.scss';
import { AboutModel } from "redux/model/basic-models";
import { Box, Typography } from "@mui/material";

interface TimelineDesProps {
    events: AboutModel[],
    currentNode: number,
    isLarge: Boolean,
}

export const TimelineDes: React.FC<TimelineDesProps> = ({events, currentNode, isLarge}) => {

    return (
        <Box height="300px" width={isLarge ? "100%" : "50%"} p={2}>
            <Typography height="250px" textAlign={'left'} className="timeline-content" variant="h6" marginBottom={5}>
                {events[currentNode-1].fulldescription}
            </Typography>
        </Box>
    );
}