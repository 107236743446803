import { Box, List, Typography, useMediaQuery, useTheme } from "@mui/material"
import { awsImg, flumeImg, golangImg, iotImg, nginxImg, reactjsImg, sparkImg } from "assets";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { ProjectList } from "./project.list";

const projectImage: Record<string, JSX.Element> = {
    flume: flumeImg,
    nginx: nginxImg,
    iot: iotImg,
    reactjs: reactjsImg,
    golang: golangImg,
    spark: sparkImg,
    aws: awsImg,
}

export const ProjectSection: React.FC = () => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const projectState = useAppSelector(state => state.basicReducer.projects);

    return (
        <Box
            py={5}
            display="flex"
            minHeight="100vh"
            justifyContent='center'
            flexDirection="column"
            textAlign="center"
            sx={{
                backgroundSize: "cover",
            }}>

            {
                isSmall ? (
                    <>
                        <Typography variant="h5" marginBottom={5}>Project</Typography>
                        <Box
                            display="flex"
                            justifyContent='center'
                            flexDirection="column"
                            textAlign="center">
                            <List sx={{ width: '100%', margin: '0 auto', bgcolor: 'background.paper' }}>
                                {
                                    projectState.map(({ title, image, subdescription }) => {
                                        return (
                                            <ProjectList
                                                project={title}
                                                image={projectImage[image]}
                                                description={subdescription}
                                                key={title}
                                            />
                                        )
                                    })
                                }
                            </List>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="h3" marginBottom={5}>Project</Typography>
                        <Box
                            display="flex"
                            justifyContent='center'
                            flexDirection="column"
                            textAlign="center">
                            <List sx={{ width: '60%', margin: '0 auto', bgcolor: 'background.paper' }}>
                                {
                                    projectState.map(({ title, image, subdescription }) => {
                                        return (
                                            <ProjectList
                                                project={title}
                                                image={projectImage[image]}
                                                description={subdescription}
                                                key={title}
                                            />
                                        )
                                    })
                                }
                            </List>
                        </Box>
                    </>
                )
            }

        </Box >
    )
}